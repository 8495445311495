<template>
  <div class="toast-area">
    <transition-group name="fade">
      <toast-alert
        v-for="notification in notifications"
        class="my-3"
        @close="closeNotification(notification)"
        :icon="notification.icon"
        :key="notification"
        :date="notification.date"
      >
        <template v-slot:title>{{ notification.title }}</template>
        <slot>{{ notification.content }}</slot>
      </toast-alert>
    </transition-group>
  </div>
</template>

<script>
import ToastAlert from "@/components/ToastAlert";

export default {
  name: "ToastArea",
  components: { ToastAlert },
  methods: {
    closeNotification(notification) {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
    },
    notify(title, content, icon) {
      icon = icon || "fas fa-exclamation";
      if (
        this.notifications.filter(
          (notification) =>
            notification.title === title && notification.content === content
        ).length !== 0
      )
        return;
      const notification = { title, content, icon, date: new Date() };
      this.notifications.push(notification);
      setTimeout(() => this.closeNotification(notification), 7000);
    },
  },
  data() {
    return {
      notifications: [],
    };
  },
};
</script>

<style lang="scss">
.toast-area {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem 1.5rem;
  z-index: 11;
}
</style>
