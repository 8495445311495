/**
 * API Index
 */

/**
 * @class
 * @classdesc API client
 * @constructor
 */
export class API {
  /**
   * Constructor
   * @param {String} baseUrl API Base URL
   */
  constructor(baseUrl) {
    this.baseUrl = baseUrl.endsWith("/") ? baseUrl : baseUrl + "/";
  }

  /**
   * Get full url using baseUrl and provided endpoint
   * @param {String} endpoint Endpoint
   */
  url(endpoint) {
    return new URL(endpoint, this.baseUrl).href;
  }

  /**
   * send GET request
   * @param {String} endpoint endpoint to be added to base url
   * @returns {Object}
   */
  async get(endpoint) {
    const url = this.url(endpoint);
    try {
      return await (await fetch(url)).json();
    } catch (error) {
      return { error };
    }
  }

  /**
   *
   * @param {String} endpoint Endpoint after base url
   * @param {Object} json optional object to be sent as a post body
   * @returns {Object}
   */
  async post(endpoint, json) {
    const url = this.url(endpoint);
    try {
      var body = json ? JSON.stringify(json) : null;
    } catch (error) {
      return { error };
    }
    try {
      return await (
        await fetch(url, {
          method: "POST",
          body,
          headers: { "Content-Type": "application/json" },
        })
      ).json();
    } catch (error) {
      if (error instanceof Response) {
        return { error: await error.json() };
      }
      return { error };
    }
  }

  /**
   * List all available datasource types
   * @returns {Array<String>}
   */
  async getDatasources() {
    return await this.get("datasource");
  }

  async getDialects() {
    return await this.get("datasource/dialect");
  }

  /**
   * Get json schema for specified datasource
   * @param {String} datasource Datasource id
   */
  async getDatasourceSchema(datasource) {
    return await this.get(`datasource/${datasource}`);
  }

  /**
   * Validate configuration and return error detail if any
   * @param {String} datasource Datasource
   * @param {Object} config Connection configuration
   */
  async validateDatasourceConfig(datasource, config) {
    return await this.post(`datasource/${datasource}`, config);
  }

  /**
   * List datasource entities
   * @param {String} datasource Datasource type
   * @param {Object} config Configuration object
   * @returns {Array<Object>}
   */
  async listDatasourceEntities(datasource, config) {
    return await this.post(`datasource/${datasource}/entities`, config);
  }

  async getEntityQuery(datasource, config, entity, dialect, rows) {
    dialect = dialect || datasource;
    rows = rows || 50;
    return await this.post(
      `datasource/${datasource}/entities/${entity}?dialect=${dialect}&rows=${rows}`,
      config
    );
  }
}
