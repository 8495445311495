<template>
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <i :class="`fa-fw ${icon} me-1`"></i>
      <strong class="me-auto"
        ><slot name="title">{{ title }}</slot></strong
      >
      <small>{{ (date || new Date()).toLocaleString() }}</small>
      <button
        type="button"
        class="btn-close"
        @click="$emit('close')"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div class="toast-body">
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastAlert",
  emits: ["close"],
  props: {
    title: String,
    content: String,
    icon: { type: String, default: "fas fa-exclamation" },
    date: Date,
  },
};
</script>

<style></style>
