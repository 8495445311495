<template>
  <div class="col-12"><h2>Code Generator</h2></div>
  <div class="form-floating">
    <select id="formatter" v-model="formatter" class="form-select">
      <option
        v-for="formatter in availableFormatters"
        :key="formatter"
        :value="formatter"
      >
        {{ formatter }}
      </option>
    </select>
    <label for="datasource-type" class="form-select-label">SQL Formatter</label>
  </div>
  <entity-code
    class="mt-5"
    v-for="entity in entities"
    :key="entity"
    :title="entity"
    :sql="generated[entity] || null"
    :formatter="formatter"
  />
  <div class="col-12 text-start">
    <a href="#" class="btn btn-outline-secondary" @click.prevent="$emit('back')"
      ><i class="fa fa-caret-left"></i>&nbsp;Back</a
    >
  </div>
</template>

<script>
import EntityCode from "@/components/EntityCode";
import { formatters } from "@/api/formatters";

export default {
  name: "CodeGenerator",
  components: { EntityCode },
  emits: ["back"],
  data() {
    return {
      generated: {},
      formatter: Object.keys(formatters)[0],
    };
  },
  computed: {
    availableFormatters() {
      return Object.keys(formatters);
    },
  },
  mounted() {
    for (const entity of this.entities) {
      this.api
        .getEntityQuery(
          this.datasourceConfig.datasource,
          this.datasourceConfig.config,
          entity,
          this.outputConfig.dialect,
          this.outputConfig.rows
        )
        .then((data) => {
          this.generated[entity] = data.query;
        });
    }
  },
  props: {
    datasourceConfig: { type: Object, required: true },
    entities: { type: Array, required: true },
    outputConfig: { type: Object, required: true },
  },
};
</script>

<style></style>
