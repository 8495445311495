<template>
  <div class="col-12"><h2>Output Configuration</h2></div>
  <div class="form-floating">
    <select id="dialect" v-model="value.dialect" class="form-select">
      <option disabled value="">Please select...</option>
      <option
        v-for="dialect in availableDialects"
        :key="dialect"
        :value="dialect"
      >
        {{ dialect }}
      </option>
    </select>
    <label for="dialect" class="form-select-label">SQL Dialect</label>
  </div>
  <div class="form-floating mt-3">
    <input
      type="number"
      class="form-control"
      id="sample-rows"
      :min="10"
      :max="500"
      v-model="value.rows"
    />
    <label for="sample-rows">Sample rows</label>
  </div>
  <div class="row mt-5">
    <div class="col-6 text-start">
      <button class="btn btn-outline-secondary" @click="$emit('back')">
        <i class="fa fa-caret-left"></i>&nbsp;Back
      </button>
    </div>
    <div class="col-6 text-end">
      <button
        @click="$emit('proceed', value)"
        class="btn btn-primary"
        v-if="value.dialect && value.rows"
      >
        Generate&nbsp;<i class="fa fa-caret-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutputConfig",
  props: {
    modelValue: Object,
  },
  emits: ["back", "proceed"],
  data() {
    return {
      availableDialects: [],
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  async mounted() {
    this.$root.progress = "Listing available SQL Dialects";
    this.availableDialects = await this.api.getDialects();
    this.$root.progress = false;
    const savedConfig = localStorage.getItem("cooltivator.cache.outputConfig");
    if (savedConfig) {
      const payload = JSON.parse(savedConfig);
      this.value.rows = payload.rows;
      this.value.dialect = payload.dialect;
    }
  },
};
</script>

<style></style>
