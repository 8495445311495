<template>
  <div>
    <h3>
      <a href="#" @click.prevent="copyToClipboard" title="Copy to clipboard"
        ><code>{{ title }}</code
        >&nbsp;<i class="far fa-copy"></i
      ></a>
    </h3>
    <highlightjs
      class="scrollable"
      v-if="sql"
      language="sql"
      :code="sqlFormatted"
    />
    <div v-else>
      Loading... <span class="spinner-border text-primary"></span>
    </div>
  </div>
</template>

<script>
import "highlight.js/styles/github.css";
import { formatters } from "@/api/formatters";

export default {
  name: "EntityCode",
  props: {
    title: String,
    sql: String,
    formatter: {
      type: String,
      default: "sqltools/formatter",
    },
  },
  computed: {
    sqlFormatted() {
      if (!this.sql) return null;
      return formatters[this.formatter](this.sql);
    },
  },
  methods: {
    copyToClipboard() {
      const target = this.$el.querySelector("pre code");
      var range, selection;
      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(target);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(target);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      navigator.clipboard.writeText(this.sqlFormatted);
    },
  },
};
</script>

<style lang="scss">
.scrollable {
  max-height: 480px;
  overflow-y: auto;
}
</style>
