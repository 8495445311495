import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import hljs from "highlight.js/lib/core";
import sql from "highlight.js/lib/languages/sql";
import hljsVuePlugin from "@highlightjs/vue-plugin";

import { API } from "./api";

const api = new API(
  process.env.VUE_APP_API_BASE_URL ||
    (process.env.NODE_ENV === "production"
      ? "https://cooltivator.bizzflow.app"
      : "http://127.0.0.1:5000/")
);

const app = createApp(App);
app.mixin({
  data() {
    return {
      api,
    };
  },
});
hljs.registerLanguage("sql", sql);
app.use(hljsVuePlugin);
app.use(router).mount("#app");
