<template>
  <div class="col-12"><h2>Entities Selection</h2></div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th><input type="checkbox" @click="selectAll" /></th>
        <th>Entity name</th>
        <th>Path</th>
        <th>Details</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="entity in availableEntities"
        :key="entity.name"
        class="cursor-pointer"
        @click="entitiesState[entity.name] = !entitiesState[entity.name]"
      >
        <td><input v-model="entitiesState[entity.name]" type="checkbox" /></td>
        <td>{{ entity.name }}</td>
        <td>{{ entity.path }}</td>
        <td>{{ entity.columns.length }} columns</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col-6 text-start">
      <button class="btn btn-outline-secondary" @click="$emit('back')">
        <i class="fas fa-caret-left"></i> Back
      </button>
    </div>
    <div class="col-6 text-end">
      <button v-if="selectedCount" @click="proceed" class="btn btn-primary">
        Process {{ selectedCount }} entities&nbsp;<i
          class="fa fa-caret-right"
        ></i>
      </button>
      <span v-else>Select at least a single entity</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntitiesSelection",
  async mounted() {
    this.$root.progress = "Listing available data source entities";
    this.availableEntities = await this.api.listDatasourceEntities(
      this.datasourceConfig.datasource,
      this.datasourceConfig.config
    );
    this.$root.progress = false;
  },
  emits: ["proceed", "back"],
  props: {
    datasourceConfig: { type: Object, required: true },
  },
  computed: {
    selectedCount() {
      return this.selected.length;
    },
    selected() {
      return Object.keys(this.entitiesState).filter(
        (key) => this.entitiesState[key]
      );
    },
  },
  methods: {
    proceed() {
      this.$emit("proceed", this.selected);
    },
    selectAll(ev) {
      this.availableEntities.forEach((entity) => {
        this.entitiesState[entity.name] = ev.target.checked;
      });
    },
  },
  data() {
    return {
      entitiesState: {},
      availableEntities: [],
      test: null,
    };
  },
};
</script>
