<template>
  <div class="col-12">
    <datasource-config
      v-model="datasourceConfig"
      v-if="step === 1"
      @proceed="datasetConfigured"
    />
    <entities-selection
      v-if="step === 2"
      :datasource-config="datasourceConfig"
      @proceed="entitiesSelected"
      @back="step = 1"
    />
    <output-config
      v-if="step === 3"
      @proceed="outputConfigured"
      @back="step = 2"
      v-model="outputConfig"
    />
    <code-generator
      v-if="step === 4"
      :datasource-config="datasourceConfig"
      :entities="selectedEntities"
      :output-config="outputConfig"
      @back="step = 3"
    />
  </div>
</template>

<script>
import DatasourceConfig from "@/components/DatasourceConfig";
import EntitiesSelection from "@/components/EntitiesSelection";
import CodeGenerator from "@/components/CodeGenerator";
import OutputConfig from "@/components/OutputConfig";

export default {
  name: "Home",
  data() {
    return {
      datasourceConfig: {},
      outputConfig: {},
      selectedEntities: [],
      step: 1,
    };
  },
  components: {
    DatasourceConfig,
    EntitiesSelection,
    CodeGenerator,
    OutputConfig,
  },
  methods: {
    datasetConfigured(config) {
      localStorage.removeItem(`cooltivator.cache.${config.datasource}`);
      localStorage.setItem(
        `cooltivator.cache.${config.datasource}`,
        JSON.stringify(config.config)
      );
      this.step = 2;
    },
    outputConfigured(config) {
      localStorage.removeItem("cooltivator.cache.outputConfig");
      localStorage.setItem(
        "cooltivator.cache.outputConfig",
        JSON.stringify(config)
      );
      this.step = 4;
    },
    entitiesSelected(entities) {
      this.selectedEntities = entities;
      this.step = 3;
    },
  },
};
</script>
