<template>
  <div class="col-12"><h2>Datasource configuration</h2></div>
  <div class="form-floating">
    <select
      id="datasource-type"
      v-model="value.datasource"
      class="form-select"
      @change="loadSchema"
    >
      <option disabled value="">Please select...</option>
      <option
        v-for="datasource in availableDatasources"
        :key="datasource"
        :value="datasource"
      >
        {{ datasource }}
      </option>
    </select>
    <label for="datasource-type" class="form-select-label"
      >Datasource type</label
    >
  </div>
  <div class="col-12 mt-4">
    <JSONEditor
      v-if="value.datasource && configSchema"
      :schema="configSchema"
      :key="configSchema"
      v-model="value.config"
    />
  </div>
  <div class="row mt-4">
    <div class="col-6 text-start">
      <a
        href="#"
        v-if="value.datasource && configSchema && value.config"
        @click.prevent="testConnection"
        class="btn btn-secondary"
        >Test Connection</a
      >
    </div>
    <div class="col-6 text-end">
      <a
        href="#"
        v-if="value.datasource && configSchema && value.config"
        @click.prevent="proceed"
        class="btn btn-primary"
        >Continue&nbsp;<i class="fa fa-caret-right"></i
      ></a>
    </div>
  </div>
</template>

<script>
import JSONEditor from "@/components/JSONEditor";
export default {
  name: "DatasourceConfig",
  components: { JSONEditor },
  emits: ["proceed"],
  props: {
    modelValue: Object,
  },
  data() {
    return {
      availableDatasources: [],
      configSchema: {},
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  inject: ["notify"],
  async mounted() {
    this.$root.progress = "Listing available data source types";
    this.availableDatasources = await this.api.getDatasources();
    if (this.value.datasource) await this.loadSchema();
    this.$root.progress = false;
  },
  methods: {
    async proceed() {
      if (await this.testConnection()) this.$emit("proceed", this.value);
    },
    async loadSchema() {
      this.configSchema = await this.api.getDatasourceSchema(
        this.value.datasource
      );
      const savedConfig = localStorage.getItem(
        `cooltivator.cache.${this.value.datasource}`
      );
      if (savedConfig) {
        this.value.config = JSON.parse(savedConfig);
      } else {
        this.value.config = {};
      }
    },
    async testConnection() {
      this.$root.progress = "Testing connection configuration";
      const response = await this.api.validateDatasourceConfig(
        this.value.datasource,
        this.value.config
      );
      if (response.detail === "OK") {
        this.notify(
          "Datasource Check",
          "Datasource check is ok. You can probably continue",
          "fas fa-thumbs-up"
        );
        this.$root.progress = false;
        return true;
      }
      this.notify(
        "Datasource Check",
        response.detail || response.error || "Unknown error ocurred"
      );
      this.$root.progress = false;
      return false;
    },
  },
};
</script>
