<template>
  <div class="json-editor"></div>
</template>

<script>
import { JSONEditor } from "@json-editor/json-editor";

export default {
  name: "JSONEditor",
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new JSONEditor(this.$el, {
      schema: this.schema,
      disable_collapse: true,
      iconlib: "fontawesome5",
      remove_button_labels: true,
      theme: "bootstrap4",
    });
    this.editor.setValue(this.value || {});
    this.editor.on("change", this.onEditorChanged);
  },
  methods: {
    onEditorChanged() {
      this.value = this.editor.getValue();
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Object,
    },
  },
};
</script>
