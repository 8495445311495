<template>
  <div
    class="consent container border border-secondary py-4 bg-light position-fixed"
  >
    <div class="row">
      <div class="col-12 text-center">
        This website uses cookies to provide its functionality and track service
        usage. Please select the cookies you
        <a href="#" @click.prevent="policyVisible = true">consent</a> to be used
        on your device.
      </div>
      <div class="col-12 text-center my-3">
        <div class="form-check form-check-inline">
          <label for="necessary-cookies" class="form-check-label"
            >Necessary cookies</label
          ><input
            type="checkbox"
            disabled
            v-model="allowNecessary"
            id="necessary-cookies"
            class="form-check-input"
          />
        </div>
        <div class="form-check form-check-inline">
          <label for="analytical-cookies" class="form-check-label"
            >Analytical cookies</label
          ><input
            type="checkbox"
            v-model="allowAnalytical"
            id="analytical-cookies"
            class="form-check-input"
          />
        </div>
      </div>
      <div class="col-12 row">
        <div class="col-6 text-end">
          <a
            href="#"
            class="btn btn-outline-secondary"
            @click.prevent="markConsent(false)"
            >Allow selected</a
          >
        </div>
        <div class="col-6 text-start">
          <a href="#" class="btn btn-primary" @click.prevent="markConsent(true)"
            >Allow All</a
          >
        </div>
      </div>
    </div>
  </div>
  <modal-dialog
    v-if="policyVisible"
    @close="policyVisible = false"
    title="Cookie Policy"
    ><cookie-policy
  /></modal-dialog>
</template>

<script>
import CookiePolicy from "@/components/CookiePolicy";
import ModalDialog from "@/components/ModalDialog";

export default {
  name: "CookieConsent",
  emits: ["consented"],
  data() {
    return {
      policyVisible: false,
      allowNecessary: true,
      allowAnalytical: false,
    };
  },
  components: { CookiePolicy, ModalDialog },
  methods: {
    markConsent(all) {
      if (all) {
        this.allowNecessary = true;
        this.allowAnalytical = true;
      }
      const exp = new Date();
      exp.setDate(exp.getDate() + 30);
      const expires = exp.toUTCString();
      document.cookie = `consent_given=1; expires=${expires}; path=/`;
      if (this.allowAnalytical)
        document.cookie = `consent_analytical=1; expires=${expires}; path=/`;
      this.$emit("consented", {
        necessary: this.allowNecessary,
        analytical: this.allowAnalytical,
      });
    },
  },
};
</script>

<style lang="scss">
div.consent {
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
</style>
