<template>
  <div class="modal d-block" style="background-color: rgba(0, 0, 0, 0.3)">
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="title">
            <h5 class="modal-title">{{ title }}</h5>
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="$emit('close')"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body row">
          <slot>{{ body }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalDialog",
  emits: ["close"],
  props: {
    body: String,
    title: String,
  },
};
</script>

<style></style>
