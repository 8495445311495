<template>
  <div>
    <header class="container-fluid">
      <nav class="navbar navbar-light bg-light">
        <div class="container-fluid">
          <h1 class="navbar-brand mb-0 h1">
            <a href="/">
              <img
                src="@/assets/bizztreatlogo.png"
                alt="Bizztreat the data
        detectives"
                style="max-height: 64px"
                class="d-inline-block align-text-middle px-3"
              />&nbsp;Bizztreat Cooltivator</a
            >
          </h1>
          <button class="btn btn-outline-danger" @click="clearLocalStorage">
            <i class="fas fa-trash"></i>&nbsp;Clear local storage
          </button>
        </div>
      </nav>
    </header>
    <div class="container" id="content">
      <router-view />
    </div>
    <footer class="container-fluid text-center mt-5 bg-light py-3">
      This website is operated by
      <a href="https://bizztreat.com">BizzTreat s.r.o.</a>
    </footer>
    <cookie-consent @consented="consentChanged" v-if="!cookiePolicyConsent" />
    <div class="progress-spinner" v-if="!!progress">
      <div class="spinner-border text-primary">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="h4">{{ progress }}</span>
    </div>
    <toast-area ref="toastArea" />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import CookieConsent from "@/components/CookieConsent";
import ToastArea from "@/components/ToastArea";

export default {
  name: "App",
  data() {
    const cookiePolicyConsent = !!this.getCookie("consent_given");
    const analyticalServicesConsent = !!this.getCookie("consent_analytical");
    return {
      cookiePolicyConsent,
      analyticalServicesConsent,
      progress: false,
    };
  },
  components: { CookieConsent, ToastArea },
  computed: {
    origin() {
      return window.location.origin;
    },
  },
  provide() {
    return {
      origin: this.origin,
      notify: this.notify,
    };
  },
  methods: {
    clearLocalStorage() {
      localStorage.clear();
    },
    notify(title, content, icon) {
      this.$refs.toastArea.notify(title, content, icon);
    },
    getCookie(name) {
      const cookiestring = RegExp(name + "=[^;]+").exec(document.cookie);
      return decodeURIComponent(
        cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
      );
    },
    consentChanged(consent) {
      this.cookiePolicyConsent = consent.necessary;
      this.analyticalServicesConsent = consent.analytical;
    },
  },
};
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic);

body,
html {
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: #070707;
}

footer a {
  color: #bb00bb;
}

#content {
  min-height: 80vh;
}

.progress-spinner {
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
}

code {
  font-family: "Roboto Mono", monospace;
}

textarea.form-control {
  min-height: 16rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
